import { types } from "@gadgetinc/mobx-quick-tree";

export enum FieldType {
  /**
   * Any value at all.
   *
   * @deprecated Prefer {@linkcode FieldType.JSON} where possible, it's more descriptive. This can't be removed until we update all Anys in the DB.
   */
  Any = "Any",

  Array = "Array",
  BelongsTo = "BelongsTo",
  Boolean = "Boolean",

  /** @deprecated unused */
  Code = "Code",

  Color = "Color",
  Computed = "Computed",
  DateTime = "DateTime",
  Email = "Email",
  EncryptedString = "EncryptedString",
  Enum = "Enum",
  File = "File",
  HasMany = "HasMany",
  HasManyThrough = "HasManyThrough",
  HasOne = "HasOne",
  ID = "ID",
  JSON = "JSON",
  Money = "Money",

  /** @deprecated unused */
  Null = "Null",

  Number = "Number",
  Object = "Object",
  Password = "Password",
  RecordState = "RecordState",
  RichText = "RichText",
  RoleAssignments = "RoleAssignments",
  String = "String",
  URL = "URL",
  Vector = "Vector",
}

export const validShopifyMetafieldFieldTypes = [
  FieldType.BelongsTo,
  FieldType.Boolean,
  FieldType.Color,
  FieldType.DateTime,
  FieldType.Email,
  FieldType.Enum,
  FieldType.JSON,
  FieldType.Number,
  FieldType.String,
  FieldType.URL,
];

export const SourceControlledFieldTypes = [
  FieldType.BelongsTo,
  FieldType.Boolean,
  FieldType.Color,
  FieldType.Computed,
  FieldType.DateTime,
  FieldType.Email,
  FieldType.EncryptedString,
  FieldType.Enum,
  FieldType.File,
  FieldType.HasMany,
  FieldType.HasManyThrough,
  FieldType.HasOne,
  FieldType.JSON,
  FieldType.Number,
  FieldType.Password,
  FieldType.RichText,
  FieldType.RoleAssignments,
  FieldType.String,
  FieldType.URL,
  FieldType.Vector,
];

export const FieldTypeMSTEnum = types.enumeration<FieldType>(Object.values(FieldType));

// The data that could be used as the title of a record
export const TitleFieldTypes = [FieldType.String, FieldType.URL, FieldType.Email, FieldType.Color, FieldType.Any];

// Data for which there's a natural, meaningful-to-humans string representation available
export const StringFieldTypes = [FieldType.String, FieldType.RichText, FieldType.URL, FieldType.Email, FieldType.Color];

// Data for which there's a numeric, meaningful-to-humans readable numeric representation available
export const NumericFieldTypes = [FieldType.Number, FieldType.Money];

// Data powered by a relationship to a record or records of another model
export const RelationshipFieldTypes = [FieldType.HasManyThrough, FieldType.HasMany, FieldType.HasOne, FieldType.BelongsTo];

// Data powered by a relationship to many records of another model
export const PluralRelationshipFieldTypes = [FieldType.HasManyThrough, FieldType.HasMany];

export const ParentRelationshipFieldTypes = [FieldType.HasManyThrough, FieldType.HasMany, FieldType.HasOne];

// Types that can be sorted.
export const SortableTypes = [
  FieldType.ID,
  FieldType.String,
  FieldType.URL,
  FieldType.Number,
  FieldType.Money,
  FieldType.Boolean,
  FieldType.DateTime,
  FieldType.Color,
  FieldType.Vector,
];

// Data types for which the record actually contains and actually owns the data.
// Excludes fields like the Gadget system fields that the developer can't really muck with, and fields like HasMany or Computed, where the value is derived from other data as opposed to a plain old scalar on the record.
export const OwnedFieldTypes = [
  FieldType.Number,
  FieldType.String,
  FieldType.Enum,
  FieldType.RichText,
  FieldType.DateTime,
  FieldType.Email,
  FieldType.URL,
  FieldType.Money,
  FieldType.File,
  FieldType.Color,
  FieldType.Boolean,
  FieldType.JSON,
  FieldType.Any,
  FieldType.BelongsTo,
  FieldType.EncryptedString,
  FieldType.Vector,
];

// Types for data that can be meaningfully differentiated by toggling case sensitivity (ie. when verifying uniqueness)
export const CaseSensitiveFieldTypes = [FieldType.String, FieldType.RichText, FieldType.Email, FieldType.URL];

import { defaults } from "lodash";

/** Bag of data details passed to an error */
export interface ErrorDetails {
  cause?: Error;
  [key: string]: any;
}

/**
 * Parent class for all the enhanced errors in any gadget-owned package
 */
export class GadgetError extends Error {
  /** Was this error caused by the Gadget application's code */
  causedByUserland = false;
  /** Was this error caused by the API client calling the Gadget application */
  causedByClient = false;
  /** What HTTP status code should be sent when responding with this error */
  statusCode = 500;
  /** A GGT_SOMETHING human/machine readable string unique error class name */
  code!: string;
  /** If this error is thrown, should we allow its code, message, and details to be sent to the client. Defaults to true for errors with 400 series status codes and false otherwise. */
  exposeToClient!: boolean;
  /** If this error is thrown, should we allow its code, message, and details to be sent to the sandbox. Defaults to true. */
  exposeToSandbox!: boolean;
  /** Optional bag of data about this error */
  details?: Record<string, any>;
  /** Was this error already logged? */
  logged = false;
}

export const _errorCodeList = [] as string[];

/**
 * Produce a GadgetError subclass that includes the nice error handling goodies, which are:
 *  - a stable, predictable, google-able `code` for all errors, which is really nice for consumers of Gadget apps
 *  - automatic addition of the error code to the message
 *  - a `statusCode` property for Starscream's default error handler to use to reply to requests with from the error handlers
 *  - a `causedByClient` property for for error handlers to decide if the error was caused by something within the requester's control. For example, should be true for an error generated by invalid GraphQL query syntax, but false for failing to connect to Postgres.
 *  - a `causedByUserland` property for error handlers to use to identify if the error was the fault of Gadget itself, or something broken with an app being built on Gadget. Should be true for an error in a Gadget app's effect code, but false for something inside Gadget's framework
 */
export const errorClass = <Code extends string>(
  code: Code,
  defaultMessage: string,
  options: {
    statusCode?: number;
    causedByClient?: boolean;
    causedByUserland?: boolean;
    exposeToClient?: boolean;
    exposeToSandbox?: boolean;
  } = {}
) => {
  const opts = defaults(options, {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: false,
    logged: false,
  });

  _errorCodeList.push(code);

  return class extends GadgetError {
    static code: Code = code;
    code: Code = code;
    statusCode = opts.statusCode;
    causedByClient = opts.causedByClient;
    causedByUserland = opts.causedByUserland;
    logged = opts.logged;
    exposeToClient = opts.exposeToClient ?? (opts.causedByClient || (opts.statusCode >= 400 && opts.statusCode < 500));
    exposeToSandbox = opts.exposeToSandbox ?? opts.causedByUserland;

    /** JS classname of this error instance */
    name!: string;

    /** Inner error which caused this error */
    cause?: Error;

    constructor(message: string = defaultMessage, readonly details?: ErrorDetails) {
      super(`${code}: ${message}`);
      this.details = details;
      if (details?.cause) {
        this.cause = details.cause;
        delete details.cause;
      }
      this.name = this.constructor.name;
    }
  };
};

/**
 * Aggregates a number of errors that occurred when processing a unit of work
 */
export class ErrorGroup extends Error {
  static code = "GGT_MANY_ERRORS" as const;
  code = "GGT_MANY_ERRORS" as const;

  constructor(public errors: GadgetError[]) {
    super(`${errors.length} error${errors.length == 1 ? "" : "s"} occurred`);
  }

  get statusCode() {
    return Math.max(...this.errors.map((e) => e.statusCode));
  }

  get causedByClient() {
    return this.errors.some((e) => e.statusCode);
  }

  get causedByUserland() {
    return this.errors.some((e) => e.statusCode);
  }
}

/**
 * Catch all error thrown when something unexpected happens within the Gadget platform that isn't directly the app developer's fault.
 * Indicates that the error is the fault of the platform, and hides the details of why in case it might leak sensitive information.
 * Try not to use this as it isn't actionable by app developers, but if something really is our fault and out of their control, it's cool.
 */
export class InternalError extends errorClass("GGT_INTERNAL_ERROR" as const, "An internal error occurred.", {
  statusCode: 500,
  causedByClient: false,
  causedByUserland: false,
}) {}

/** Thrown when an app tries to run start/stop transaction calls in the wrong order */
export class GraphQLTransactionSequenceError extends errorClass(
  "GGT_INCORRECT_TRANSACTION_SEQUENCE" as const,
  "Can't take this operation right now",
  {
    statusCode: 500,
    causedByClient: true,
    causedByUserland: false,
    exposeToSandbox: true,
  }
) {}

/** Thrown when an app runs a transaction that takes too long that we then abort */
export class ClientTransactionTimeoutError extends errorClass(
  "GGT_TRANSACTION_TIMEOUT" as const,
  "This transaction took too long before being committed, all the work within it needs to be completed within 5 seconds.",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: true,
    exposeToSandbox: true,
  }
) {}

/** Thrown when an app runs a particular database statement that takes too long, which the database then aborts */
export class DatabaseOperationTimeoutError extends errorClass(
  "GGT_DATABASE_OPERATION_TIMEOUT" as const,
  "One operation in the backend database took too long to execute and was cancelled.",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: true,
  }
) {
  constructor(message?: string, details?: { query?: string; parameters?: any[] | undefined; driverError?: any } & ErrorDetails) {
    super(message, details);
  }
}

/** Thrown when trying to access an application that cannot be found. Often happens while an app is being deleted. */
export class ApplicationNotFoundError extends errorClass("GGT_APPLICATION_NOT_FOUND" as const, "Application not found", {
  statusCode: 404,
  causedByClient: true,
  causedByUserland: false,
}) {
  readonly applicationId: string | undefined;

  constructor(message?: string, details?: { applicationId?: string } & ErrorDetails) {
    super(message, details);
    this.applicationId = details?.applicationId;
  }
}

/** Thrown when trying to access an environment that cannot be found. Often happens while an app is being deleted. */
export class EnvironmentNotFoundError extends errorClass("GGT_ENVIRONMENT_NOT_FOUND" as const, "Environment not found", {
  statusCode: 404,
  causedByClient: true,
  causedByUserland: false,
}) {
  readonly environmentId: string | undefined;

  constructor(message?: string, details?: { environmentId?: string } & ErrorDetails) {
    super(message, details);
    this.environmentId = details?.environmentId;
  }
}

/** Thrown when an app tries to read or take action on a specific record by ID, but that record can't be found */
export class NotFoundError extends errorClass("GGT_RECORD_NOT_FOUND" as const, "The record couldn't be found in the database.", {
  statusCode: 404,
  causedByClient: true,
  causedByUserland: false,
}) {}

export interface PermissionDeniedDetails extends ErrorDetails {
  actor?: string | Record<string, any>;
  actorRoleKeys?: string[];
  resource?: Record<string, any>;
}
/** Thrown when an API client tries to access data that it's roles don't grant it access to. */
export class PermissionDeniedError extends errorClass("GGT_PERMISSION_DENIED" as const, "Permission denied to access this resource.", {
  statusCode: 403,
  causedByClient: true,
  causedByUserland: false,
}) {
  actor?: string | Record<string, any>;
  actorRoleKeys?: string[];
  resource?: Record<string, any>;

  constructor(message?: string, readonly details: PermissionDeniedDetails = {}) {
    super(message, details);
    this.actor = details.actor;
    this.actorRoleKeys = details.actorRoleKeys;
    this.resource = details.resource;
  }
}

/** Thrown when an API client tries to retrieve or mutate a field on a model that doesn't exist */
export class UnknownFieldError extends errorClass(
  "GGT_UNKNOWN_FIELD" as const,
  "Trying to retrieve or store a field apiIdentifier that doesn't exist",
  {
    statusCode: 500,
    causedByClient: true,
    causedByUserland: false,
  }
) {}

/** Thrown when a GraphQL query makes a request with a value for an ID that doesn't look like an ID */
export class InvalidIDError extends errorClass(
  "GGT_INVALID_ID" as const,
  "Couldn't convert to a Gadget ID, it must be a string containing only digits",
  {
    statusCode: 422,
    causedByClient: true,
    causedByUserland: true,
  }
) {}

/** Thrown when a GraphQL query makes a request with a value for an ID that doesn't look like an ID */
export class InvalidBackgroundActionIDError extends errorClass(
  "GGT_INVALID_BACKGROUND_ACTION_ID" as const,
  "The provided data wasn't a valid background action identifier",
  {
    statusCode: 422,
    causedByClient: true,
    causedByUserland: true,
  }
) {}

/** Thrown when an API request specifies an invalid argument for some field */
export class InvalidQueryInputError extends errorClass("GGT_INVALID_QUERY_INPUT" as const, "Input was invalid for a query field", {
  statusCode: 422,
  causedByClient: true,
  causedByUserland: false,
}) {}

export class InvalidFilterError extends errorClass("GGT_INVALID_FILTER" as const, "Query filter was invalid", {
  statusCode: 422,
  causedByClient: true,
  causedByUserland: false,
}) {}

/** Thrown either when an API request specifies an unknown action in the input, or multiple nested actions in a single blob */
export class InvalidActionInputError extends errorClass("GGT_INVALID_ACTION_INPUT" as const, "Input was invalid for an action", {
  statusCode: 422,
  causedByClient: true,
  causedByUserland: false,
}) {}

export class InvalidActionResultError extends errorClass("GGT_INVALID_ACTION_RESULT" as const, "Result was invalid for an action", {
  statusCode: 422,
  causedByClient: false,
  causedByUserland: true,
}) {}

/** Thrown when an API request retrieves a record who's stored data no longer matches the validations for that model*/
export class InvalidStoredDataError extends errorClass(
  "GGT_INVALID_STORED_DATA" as const,
  "The database has stored data which is not valid for the current schema and must be corrected to be read",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: true,
  }
) {}

/** Represents permission being denied when an api key is passed but it isn't valid */
export class InvalidAPIKeyError extends errorClass(
  "GGT_INVALID_API_KEY" as const,
  "Invalid API key. Please ensure you're passing an API Key directly copied from the Gadget Editor, which is a string starting with `gsk-`.",
  {
    statusCode: 403,
    causedByClient: true,
    causedByUserland: false,
  }
) {}

/** The permission denied error thrown when a request is using the internal auth mechanism but doesn't provide a valid token */
export class InvalidInternalAuthTokenError extends errorClass("GGT_INVALID_INTERNAL_AUTH_TOKEN" as const, "Invalid internal auth token.", {
  statusCode: 403,
  causedByClient: false,
  causedByUserland: false,
  exposeToSandbox: true,
}) {}

/** Thrown when trying to make a request using an auth mechanism that doesn't make sense */
export class InvalidAuthModeError extends errorClass("GGT_INVALID_AUTH_MODE" as const, "Invalid authentication mode.", {
  statusCode: 403,
  causedByClient: true,
}) {}

/** Thrown when trying to process a request for an application that has broken relationships set up among its models */
export class MisconfiguredFieldError extends errorClass(
  "GGT_MISCONFIGURED_FIELD" as const,
  "A relationship type field (Has One, HasMany, Has Many Through or Belongs To) is set up incorrectly.",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: true,
  }
) {
  get extensions() {
    return {
      problems: this.details?.problems,
    };
  }
}

/** Thrown when trying to process a request for an application where a field's storage has yet to be set up */
export class DatabaseNotReadyError extends errorClass(
  "GGT_DATABASE_NOT_READY" as const,
  "The database is still being set up for the model and is not ready for reading/writing",
  {
    statusCode: 500,
    causedByClient: true,
    causedByUserland: false,
  }
) {}

/** Thrown when trying to insert/update a record that violates a uniqueness validation */
export class NonUniqueConstraintError extends errorClass(
  "GGT_NON_UNIQUE_DATA" as const,
  "Data is being added or changed on a record which is non unique among the other records of the model and is thus invalid.",
  {
    statusCode: 422,
    causedByClient: false,
    causedByUserland: true,
  }
) {}

/** Thrown when the storage strategy doesn't support unique indexes */
export class UniqueValidationNotSupportedError extends errorClass(
  "GGT_UNIQUE_VALIDATION_NOT_SUPPORTED" as const,
  "Unique validation is not supported by this storage strategy.",
  {
    statusCode: 422,
    causedByClient: false,
    causedByUserland: true,
  }
) {}

/** Thrown when invalid JSON schema is used in input params */
export class UnknownInputTypeError extends errorClass(
  "GGT_UNKNOWN_INPUT_TYPE" as const,
  "An unknown input param type was encountered in the schema",
  {
    statusCode: 422,
    causedByClient: true,
    causedByUserland: false,
  }
) {}

/** Thrown when trying to access the `currentSession` field of a GraphQL API and the requestor doesn't have a session set up */
export class InactiveSessionAccessError extends errorClass(
  "GGT_INACTIVE_SESSION_ERROR" as const,
  "Can't access or act on a session without a session active.",
  {
    statusCode: 422,
    causedByClient: true,
    causedByUserland: false,
  }
) {}

/**
 * Represents what is thrown when a user could not be set on a newly signed in session
 */
export class UserNotSetOnSessionError extends errorClass("GGT_USER_NOT_SET_ON_SESSION" as const, "User not set on session", {
  causedByClient: true,
  causedByUserland: false,
}) {}

/**
 * Represents what is thrown when there is no authenticated user in scope after logging in
 */
export class NoSessionForAuthenticationError extends errorClass(
  "GGT_NO_SESSION_FOR_AUTHENTICATION" as const,
  "There is no authenticated user in scope.",
  {
    causedByClient: true,
    causedByUserland: false,
  }
) {}

/**
 * Thrown when a token was issued for a different environment within the same app
 */
export class IncorrectEnvironmentTokenError extends errorClass(
  "GGT_INCORRECT_ENVIRONMENT" as const,
  "Token is not valid for this environment",
  {
    causedByClient: true,
    causedByUserland: false,
    statusCode: 403,
  }
) {}

/**
 * Thrown when a token is used for the incorrect app
 */
export class IncorrectAppTokenError extends errorClass("GGT_INCORRECT_APP" as const, "Token is not valid for this app", {
  causedByClient: true,
  causedByUserland: false,
  statusCode: 401,
}) {}

/**
 * Represents what is thrown when there is an expired session token
 */
export class ExpiredSessionForAuthenticationError extends errorClass(
  "GGT_EXPIRED_SESSION_FOR_AUTHENTICATION" as const,
  "The session is not longer valid or has expired",
  {
    causedByClient: true,
    causedByUserland: false,
    statusCode: 401,
  }
) {}

/**
 * Thrown when there's an issue with the authorization code that was sent in the token exchange request
 */
export class AuthorizationCodeValidationError extends errorClass(
  "GGT_AUTH_INVALID_GRANT" as const,
  "The provided authorization grant (e.g., authorization code, resource owner credentials) or refresh token is invalid, expired, revoked, does not match the redirection URI used in the authorization request, or was issued to another client.",
  {
    causedByClient: true,
    causedByUserland: false,
    statusCode: 400,
  }
) {}

/**
 * Thrown when a code verifier is not included with an authorization code
 */
export class AuthorizationInvalidRequest extends errorClass("GGT_AUTH_INVALID_REQUEST" as const, "", {
  causedByClient: true,
  causedByUserland: false,
  statusCode: 400,
}) {}

/** Thrown when a plan item references another plan item that doesn't exist (e.g., via `_linkVia`) */
export class UnknownPlanItemError extends errorClass(
  "GGT_UNKNOWN_PLAN_ITEM" as const,
  "Execution plan item referenced an unknown plan item",
  {
    statusCode: 422,
    causedByClient: false,
    causedByUserland: false,
  }
) {}

/** Thrown when a plan name is in Gadget's unsupported types for shop syncs */
export class ShopifyInvalidPlanNameError extends errorClass(
  "GGT_INVALID_PLAN_NAME" as const,
  "Shop with plan name of ['frozen', 'cancelled', 'fraudulent'] is invalid for syncing.",
  {
    statusCode: 422,
    causedByClient: false,
    causedByUserland: true,
    exposeToSandbox: true,
  }
) {}

/** Represents what is thrown when an action can't be taken on a record because it's an illegal state transition */
export class NoTransitionError extends errorClass("GGT_NO_TRANSITION" as const, "Invalid action", {
  statusCode: 422,
  causedByClient: true,
  causedByUserland: false,
}) {}

export class InvalidStateTransitionError extends errorClass("GGT_INVALID_STATE_TRANSITION" as const, "Invalid state transition", {
  statusCode: 422,
  causedByClient: false,
  causedByUserland: true,
}) {}

/** Represents what is thrown when Gadget wants to work with a model but the model can't be found */
export class MissingRequiredModelError extends errorClass(
  "GGT_MISSING_REQUIRED_MODEL" as const,
  "Gadget needs a specific model to exist in the application to perform this operation, but the model does not exist.",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: true,
  }
) {}

/** Represents what is thrown when Gadget wants to invoke an action on a model but the action can't be found */
export class MissingRequiredActionError extends errorClass(
  "GGT_MISSING_REQUIRED_ACTION" as const,
  "Gadget needs a specific action to exist on a model to perform this operation, but the action does not exist.",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: true,
  }
) {}

/** Represents what is thrown when Gadget wants to invoke an action but the trigger can't be found */
export class MissingRequiredTriggerError extends errorClass(
  "GGT_MISSING_REQUIRED_TRIGGER" as const,
  "Gadget needs a specific trigger to exist on a model to perform this operation, but the trigger does not exist.",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: true,
  }
) {}

/** Thrown when a query for a field fails  */
export class MissingRequiredFieldError extends errorClass(
  "GGT_MISSING_REQUIRED_FIELD" as const,
  "Gadget needs a specific field to exist on a model to perform this operation, but the field does not exist.",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: true,
  }
) {}

/** Thrown when a query for a field storage epoch fails  */
export class MissingRequiredFieldStorageEpochError extends errorClass(
  "GGT_MISSING_REQUIRED_FIELD_STORAGE_EPOCH" as const,
  "Gadget needs a specific field storage epoch to exist on a field to perform this operation, but the epoch does not exist.",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: true,
  }
) {}

/** Represents what is thrown when Gadget wants to access data of a record but the data is not present */
export class MissingRequiredDataError extends errorClass(
  "GGT_MISSING_REQUIRED_DATA" as const,
  "Gadget needs a specific field to be set on a record to perform this operation, but the field is null or undefined.",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: true,
  }
) {}

/** Represents what is thrown when Gadget wants to access data of a record but the data is not present */
export class MissingRequiredValidationError extends errorClass(
  "GGT_MISSING_REQUIRED_VALIDATION" as const,
  "Gadget needs a specific validation to exist on a model to perform this operation, but the validation does not exist",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: true,
  }
) {}

/** Represents what is thrown when an action can't be taken on a record because the preconditions failed */
export class PreconditionsFailedForActionError extends errorClass("GGT_PRECONDITIONS_FAILED" as const, "Invalid action", {
  statusCode: 422,
  causedByClient: true,
  causedByUserland: false,
}) {}

/** Represents what is thrown when something unexpected goes wrong and the state machine can't execute */
export class ActionTimeoutError extends errorClass("GGT_ACTION_TIMEOUT" as const, "Timed out while executing action", {
  causedByClient: false,
  causedByUserland: true,
}) {}

/** Thrown when an action is trying to execute but can't because it's missing key configuration or some configuration value is itself invalid. This is the app developer's fault -- the action needs to be corrected in order to work. */
export class MisconfiguredActionError extends errorClass(
  "GGT_MISCONFIGURED_ACTION" as const,
  "Invalid action configuration, request cannot be processed until this is corrected.",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: true,
  }
) {}

/** Thrown when a validation is trying to execute but can't because it's missing key configuration or some configuration value is itself invalid. This is the app developer's fault -- the validation needs to be corrected in order to work. */
export class MisconfiguredValidationError extends errorClass(
  "GGT_MISCONFIGURED_VALIDATION" as const,
  "Invalid validation configuration, request cannot be processed until this is corrected.",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: true,
  }
) {}

/** Thrown when a code snippet is linked by a Gadget object (like an effect or a computed field) but the corresponding file for the linked path isn't found in the application's filesystem. */
export class MisconfiguredCodeSnippetError extends errorClass(
  "GGT_MISCONFIGURED_CODE_SNIPPET" as const,
  "Code snippet referenced unknown file, request cannot be processed until this is corrected.",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: true,
  }
) {}

/**
 * Represents an error caused by the user trying to do something that can't be done right now
 * Not an exceptional circumstance from Gadget's point of view, but we still use error handling to implement user feedback in these situations for mobx-state-tree's atomic support
 */
export class UserInitiatedActionSemanticError extends errorClass(
  "GGT_USER_INITIATED_ACTION_SEMANTIC_ERROR" as const,
  "User initiated action",
  {
    statusCode: 500,
    causedByClient: true,
    causedByUserland: false,
  }
) {}

/**
 * Represents an error caused by backend data not being returned from the backend. This is unexpected -- it probably means there is an issue with the network connection or a backend bug. Gadget's fault.
 */
export class FailedNetworkOperationError extends errorClass(
  "GGT_FAILED_NETWORK_OPERATION" as const,
  "There was an unexpected error communicating with the Gadget backend. Please try again.",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: false,
    exposeToSandbox: true,
  }
) {}

/**
 * Represents the error thrown when trying to apply a JSON field's default but the stored default value isn't a valid JSON string
 */
export class InvalidJSONDefaultError extends errorClass("GGT_INVALID_JSON_DEFAULT" as const, "Invalid JSON default value", {
  causedByUserland: true,
}) {
  constructor(originalMessage: string, details?: ErrorDetails) {
    super(`Invalid JSON default, cannot apply to record. Parse error: ${originalMessage}`, details);
  }
}

/**
 * Thrown when an API call arrives at an application that isn't done being created.
 */
export class StillSettingUpError extends errorClass(
  "GGT_STILL_SETTING_UP" as const,
  "This Gadget application is still being set up in the background and isn't yet ready to serve requests. Please try again in a few seconds.",
  {
    statusCode: 503, // service unavailable
    causedByClient: true, // strictly speaking not really, but we set this to avoid reporting this error as the platform's fault
    causedByUserland: false,
    exposeToClient: true,
    exposeToSandbox: true,
  }
) {}

export class FatalError extends errorClass(
  "GGT_FATAL" as const,
  "This Gadget application has experienced a fatal error and cannot serve requests.",
  {
    statusCode: 503, // service unavailable
    causedByClient: true, // strictly speaking not really, but we set this to avoid reporting this error as the platform's fault
    causedByUserland: false,
    exposeToClient: true,
    exposeToSandbox: true,
  }
) {}

/**
 * Thrown when an API call arrives at an environment that hasn't been deployed
 */
export class UndeployedEnvironmentError extends errorClass(
  "GGT_UNDEPLOYED_ENVIRONMENT" as const,
  "This Gadget application has not been deployed to Production.",
  {
    statusCode: 422,
    causedByClient: true,
    causedByUserland: false,
    exposeToClient: true,
    exposeToSandbox: true,
  }
) {}

/**
 * Represents the error thrown when the application's package.json file doesn't follow the JSON or package.json specification:
 * - https://www.json.org/json-en.html
 * - https://docs.npmjs.com/cli/v8/configuring-npm/package-json
 */
export class BrokenPackageJSONError extends errorClass(
  "GGT_BROKEN_PACKAGE_JSON" as const,
  "The application's package.json file is not valid",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: true,
  }
) {
  constructor(originalMessage: string, details?: ErrorDetails) {
    super(`The application's package.json file is not valid. Parse error: ${originalMessage}`, details);
  }
}

/** Thrown when an application hits its rate limit */
export class TooManyRequestsError extends errorClass(
  "GGT_TOO_MANY_REQUESTS" as const,
  "The application has received too many requests and has exhausted its available resources. Try again after waiting a little while so more resources are available.",
  {
    statusCode: 429,
    causedByClient: true,
  }
) {
  constructor(originalMessage?: string, details?: { waitTimeSeconds?: number } & ErrorDetails) {
    super(originalMessage, details);
  }
}

/** Thrown when an application hits its rate limit */
export class InfiniteLoopError extends errorClass(
  "GGT_INFINITE_LOOP_DETECTED" as const,
  "The application has triggered an infinite loop detection, the same resource has been updated too many times in quick succession. Check to see if you are updating a model from that model's update effect.",
  {
    statusCode: 400,
    causedByClient: true,
  }
) {}

/** Thrown when an application asks for a page size bigger than the maximum allowed */
export class InvalidPageSizeError extends errorClass(
  "GGT_INVALID_PAGE_SIZE" as const,
  "The application has requested a page size that is too large. Please try again with a smaller page size.",
  {
    statusCode: 400,
    causedByClient: true,
  }
) {}

/** Thrown when backwards pagination is not supported */
export class InvalidBackwardsPaginationError extends errorClass(
  "GGT_INVALID_BACKWARDS_PAGINATION" as const,
  "Paginating a related connection backwards is not supported.",
  {
    statusCode: 400,
    causedByClient: true,
  }
) {}

/** Thrown when the sandbox can't run requests because the process is still starting up and isn't listening yet */
export class SandboxReloadingError extends errorClass(
  "GGT_SANDBOX_RELOADING" as const,
  "Can't make requests to the application because its backend process is reloading and not ready to serve requests",
  {
    statusCode: 500,
    causedByUserland: true,
  }
) {}

/** Thrown when the sandbox can't run requests because the process failed to boot or crashed */
export class AppCrashError extends errorClass("GGT_APP_CRASH" as const, "The application has crashed and can no longer serve requests", {
  statusCode: 500,
  causedByUserland: true,
}) {}

/** Thrown when we're trying to process data from a remote system and it's missing an ID that we need to be present */
export class InvalidShopifySessionTokenError extends errorClass(
  "GGT_INVALID_SHOPIFY_SESSION_TOKEN" as const,
  "Invalid Shopify Session Token provided",
  {
    statusCode: 401,
    causedByClient: true,
    causedByUserland: false,
  }
) {}

/** Thrown when we receive 401 Unauthorized from Shopify's API */
export class ShopifyAuthenticationError extends errorClass(
  "GGT_SHOPIFY_AUTHENTICATION_ERROR" as const,
  "Unable to authenticate with Shopify",
  {
    statusCode: 401,
    causedByClient: true,
    causedByUserland: false,
  }
) {}

/** Thrown when we receive 403 Forbidden from Shopify's API */
export class ShopifyForbiddenError extends errorClass("GGT_SHOPIFY_FORBIDDEN_ERROR" as const, "Invalid Shopify permissions", {
  statusCode: 403,
  causedByClient: true,
  causedByUserland: false,
}) {}

/** Thrown when we receive 401 Unauthorized from BigCommerce's API */
export class BigCommerceAuthenticationError extends errorClass(
  "GGT_BIGCOMMERCE_AUTHENTICATION_ERROR" as const,
  "Unable to authenticate with BigCommerce",
  {
    statusCode: 401,
    causedByClient: true,
    causedByUserland: false,
  }
) {}

/** Thrown when we're trying to process data from a remote system and it's missing an ID that we need to be present */
export class MissingIdentifierInRemoteBlobError extends errorClass(
  "GGT_MISSING_REMOTE_IDENTIFIER" as const,
  "Can't process input data from a remote system because it's missing an identifier Gadget expects to exist",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: false,
    exposeToSandbox: true,
  }
) {}

/** Thrown when a request tries to fork an app it doesn't have permission to fork */
export class UnforkableAppError extends errorClass(
  "GGT_UNFORKABLE_APP" as const,
  "Can't fork this application, please ensure it exists and has permitted forking",
  {
    statusCode: 401,
    causedByClient: true,
    causedByUserland: false,
  }
) {}

/** Throw when an unknown Webhook registration occurs */
export class WebhookRegistrationError extends errorClass(
  "GGT_WEBHOOK_REGISTRATION_FAILED" as const,
  "An error occurred while registering webhook(s)",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: false,
    exposeToSandbox: true,
  }
) {
  constructor(message?: string, details: { causedByClient?: boolean } = {}) {
    super(message);
    this.causedByClient = details.causedByClient ?? false;
  }
}

/** Throw when an application is unavailable because it is suspended */
export class ApplicationLockedError extends errorClass("GGT_APPLICATION_LOCKED" as const, "This application is unavailable.", {
  statusCode: 423,
  causedByClient: false,
  causedByUserland: true,
  exposeToSandbox: true,
}) {}

export class DeployDisallowedError extends errorClass("GGT_DEPLOY_DISALLOWED" as const, "This application is not allowed to be deployed.", {
  statusCode: 406,
  causedByClient: false,
  causedByUserland: true,
  exposeToSandbox: true,
}) {}

export interface PaymentRequiredDetails extends ErrorDetails {
  requiresUpgrade?: boolean;
  requiresAdditionalCharge?: boolean;
  additionalChargeAmount?: string;
}

/** Throw when an application is unavailable because payment is required */
export class PaymentRequiredError extends errorClass("GGT_PAYMENT_REQUIRED" as const, "Payment is required for this application.", {
  statusCode: 402,
  causedByClient: false,
  causedByUserland: true,
  exposeToSandbox: true,
}) {
  requiresUpgrade?: boolean;
  requiresAdditionalCharge?: boolean;
  additionalChargeAmount?: string;

  constructor(message?: string, details: PaymentRequiredDetails = {}) {
    super(message, details);
    this.requiresUpgrade = details.requiresUpgrade;
    this.requiresAdditionalCharge = details.requiresAdditionalCharge;
    this.additionalChargeAmount = details.additionalChargeAmount;
  }
}

/** Throw when a developer is accessing a shopify shop which is in the 'frozen' state - meaning they have unpaid bills/dues to shopify*/
export class ShopifyFrozenError extends errorClass("GGT_SHOPIFY_FROZEN" as const, "Unable to access shop because of unpaid Shopify dues", {
  statusCode: 402,
  causedByClient: false,
  causedByUserland: true,
  exposeToSandbox: true,
}) {}

/** Throw when a client's shopify shop throw's errors akin to the tune of unavailablility*/
export class ShopifyUnavailableError extends errorClass(
  "GGT_SHOPIFY_UNAVAILABLE" as const,
  "The Shopify shop is unavailable at this time, please try again later",
  {
    statusCode: 404,
    causedByClient: false,
    causedByUserland: true,
  }
) {}

/** Thrown as a catchall when we are unsure why the shopifyAPI returns an unhandled response */
export class ShopifyRequestError extends errorClass(
  "GGT_SHOPIFY_REQUEST_ERROR" as const,
  "An error occurred making an API call to Shopify",
  {
    statusCode: 400,
    causedByClient: false,
    causedByUserland: true,
  }
) {}

/** Thrown when a request to shopify responds with HTTP 429 and it won't be retried */
export class ShopifyRateLimitError extends errorClass(
  "GGT_SHOPIFY_RATE_LIMIT" as const,
  "You have exceeded Shopify's API rate limit. Please try again later.",
  {
    statusCode: 429,
    causedByClient: false,
    causedByUserland: true,
  }
) {}

/** Throw when an unknown Webhook registration occurs */
export class AssetBuildFailedError extends errorClass(
  "GGT_ASSET_BUILD_FAILED" as const,
  "An error occurred while building production assets",
  {
    statusCode: 500,
    causedByClient: false,
    causedByUserland: true,
  }
) {}

/** Throw when an operation tries to compare vectors with different dimensions */
export class DifferentVectorDimensionsError extends errorClass(
  "GGT_DIFFERENT_VECTOR_DIMENSIONS" as const,
  "This operation tried to compare vectors with different dimensions, which is an undefined operation. Please ensure all stored data and input data has the same number of dimensions.",
  {
    statusCode: 500,
    causedByClient: true,
    causedByUserland: true,
  }
) {}

/** Throw when validating a trigger in GraphQL fails */
export class InvalidActionTrigger extends errorClass(
  "GGT_INVALID_ACTION_TRIGGER" as const,
  "The trigger provided is missing properties and cannot be processed.",
  {
    statusCode: 400,
    causedByClient: true,
  }
) {}

/** Throw when you need a generic client error */
export class BadRequestError extends errorClass("GGT_BAD_REQUEST" as const, "Bad request", {
  statusCode: 400,
  causedByClient: true,
  causedByUserland: true,
}) {}

export class InvalidEmailError extends errorClass("GGT_INVALID_EMAIL_REQUEST" as const, "Invalid Email input", {
  statusCode: 400,
  causedByClient: true,
  causedByUserland: true,
}) {}

/** Thrown when an error occurs with database indexing */
export class PostgresIndexError extends errorClass("GGT_PSQL_INDEX_ERROR" as const, "Bad request", {
  statusCode: 500,
  causedByClient: false,
  causedByUserland: false,
}) {}

/** Thrown when an invalid value is returned from an action's run effect */
export class InvalidActionReturnType extends errorClass(
  "GGT_ACTION_RETURN_TYPE" as const,
  "Invalid value returned from action run effect",
  {
    statusCode: 500,
    causedByUserland: true,
    causedByClient: false,
  }
) {}

/** Thrown when adding adding a trigger that exposes a public API and one already exists on the action */
export class InvalidPublicApiTrigger extends errorClass(
  "GGT_INVALID_API_TRIGGER" as const,
  "This action cannot have more than 1 trigger that is a public API trigger",
  {
    statusCode: 400,
    causedByClient: true,
    causedByUserland: true,
  }
) {
  action?: string;
  model?: string;
  triggers?: string[];

  constructor(
    message?: string,
    details?: {
      action?: string;
      model?: string;
      triggers?: string[];
    }
  ) {
    super(message);
    this.action = details?.action;
    this.model = details?.model;
    this.triggers = details?.triggers;
  }
}

/**
 * Thrown when a User signIn attempt fails due to an invalid email or password.
 */
export class InvalidEmailPasswordError extends errorClass("GGT_INVALID_EMAIL_PASSWORD" as const, "Invalid email or password", {
  statusCode: 401,
  causedByClient: true,
  causedByUserland: false,
}) {}

/**
 * Thrown when a reset password link is followed with an invalid or expired token.
 */
export class InvalidResetPasswordTokenError extends errorClass(
  "GGT_INVALID_RESET_PASSWORD_TOKEN" as const,
  "Invalid or expired password reset token",
  {
    statusCode: 401,
    causedByClient: true,
    causedByUserland: false,
  }
) {}

/**
 * Thrown when a verify email link is followed with an invalid or expired token.
 */
export class InvalidEmailVerificationToken extends errorClass(
  "GGT_INVALID_EMAIL_VERIFICATION_TOKEN" as const,
  "Invalid or expired email verification token",
  {
    statusCode: 401,
    causedByClient: true,
    causedByUserland: false,
  }
) {}

/**
 * Thrown when a User changePassword attempt fails due to an invalid currentPassword.
 */
export class InvalidCurrentPasswordError extends errorClass("GGT_INVALID_CURRENT_PASSWORD" as const, "The current password was incorrect", {
  statusCode: 401,
  causedByClient: true,
  causedByUserland: false,
}) {}

/**
 * Thrown when a User signIn attempt fails due to requiring email verification.
 */
export class EmailUnverifiedError extends errorClass(
  "GGT_EMAIL_VERIFICATION_REQUIRED" as const,
  "User must verify their email before signing in",
  {
    statusCode: 401,
    causedByClient: true,
    causedByUserland: false,
  }
) {}

export class InvalidPasswordResetRequest extends errorClass(
  "GGT_INVALID_PASSWORD_RESET_REQUEST" as const,
  "Invalid password reset request",
  {
    statusCode: 400,
    causedByClient: true,
    causedByUserland: false,
  }
) {}

export class EmailNotFoundError extends errorClass("GGT_EMAIL_NOT_FOUND" as const, "Email not found", {
  statusCode: 404,
  causedByClient: true,
  causedByUserland: false,
}) {}

/**
 * Thrown when ggt sends file changes to Gadget, but ggt's files version
 * is out of date.
 */
export class FilesVersionMismatch extends errorClass("GGT_FILES_VERSION_MISMATCH" as const, "Files version mismatch", {
  statusCode: 409,
  causedByClient: false, // technically, this is caused by the client, but we don't want this error to show up in our user's logs
  causedByUserland: false,
}) {
  constructor(details: { expected: string; actual: string }) {
    super(undefined, details);
  }
}

/**
 * Thrown when the sandbox process crashes due to application code tearing down the worker process.
 * Example is an Unhandled promise rejection
 * We use the language sandbox for internal clarification but avoid it for the users 'sandbox'. The less they know the less they have to worry about
 */
export class BackendProcessCrash extends errorClass("GGT_BACKEND_PROCESS_CRASH" as const, "Backend process crash", {
  statusCode: 500,
  causedByClient: false,
  causedByUserland: true,
}) {}

/**
 * Thrown when a client tries to enqueue a background action with an id that already exists
 */
export class DuplicateBackgroundActionIDError extends errorClass(
  "GGT_DUPLICATE_BACKGROUND_ACTION_ID" as const,
  "This background action can't be enqueued as its ID is already in use by another background action.",
  {
    statusCode: 422,
    causedByClient: true,
    causedByUserland: false,
  }
) {}

/**
 * Thrown when combining a `search:` with unsupported sorts or filters
 */
export class UnsupportedInSearchError extends errorClass(
  "GGT_UNSUPPORTED_SEARCH" as const,
  "This functionality is not supported in combination with a `search:` on this model.",
  {
    statusCode: 422,
    causedByClient: true,
    causedByUserland: false,
  }
) {}

/**
 * This is a generic error thrown whenever a database error is encountered. Generally we want to advocate for retrying, of course respecting an idempotent context
 */
export class DatabaseError extends errorClass("GGT_DATABASE_ERROR" as const, "Platform database error, please try again", {
  statusCode: 500,
  causedByClient: false,
  causedByUserland: true,
}) {
  constructor(message?: string, details?: ErrorDetails) {
    super(message, details);
  }
}

/**
 * Thrown when there are too many concurrent syncs running for on a shopify connection for a given shop. This usually indicates a bug in their code.
 */
export class TooManySyncsError extends errorClass("GGT_TOO_MANY_SYNCS" as const, "Too many concurrent syncs running", {
  statusCode: 429,
  causedByClient: false,
  causedByUserland: true,
}) {}

export const GadgetErrors = [
  ActionTimeoutError,
  AppCrashError,
  ApplicationLockedError,
  ApplicationNotFoundError,
  AssetBuildFailedError,
  AuthorizationCodeValidationError,
  AuthorizationInvalidRequest,
  BackendProcessCrash,
  BadRequestError,
  BigCommerceAuthenticationError,
  BrokenPackageJSONError,
  ClientTransactionTimeoutError,
  DatabaseError,
  DatabaseNotReadyError,
  DatabaseOperationTimeoutError,
  DeployDisallowedError,
  DifferentVectorDimensionsError,
  DuplicateBackgroundActionIDError,
  EmailNotFoundError,
  EmailUnverifiedError,
  EnvironmentNotFoundError,
  ExpiredSessionForAuthenticationError,
  FailedNetworkOperationError,
  FatalError,
  FilesVersionMismatch,
  GraphQLTransactionSequenceError,
  InactiveSessionAccessError,
  IncorrectAppTokenError,
  IncorrectEnvironmentTokenError,
  InfiniteLoopError,
  InternalError,
  InvalidActionInputError,
  InvalidActionResultError,
  InvalidActionReturnType,
  InvalidActionTrigger,
  InvalidAPIKeyError,
  InvalidAuthModeError,
  InvalidBackgroundActionIDError,
  InvalidBackwardsPaginationError,
  InvalidCurrentPasswordError,
  InvalidEmailError,
  InvalidEmailPasswordError,
  InvalidEmailVerificationToken,
  InvalidIDError,
  InvalidInternalAuthTokenError,
  InvalidJSONDefaultError,
  InvalidPageSizeError,
  InvalidPasswordResetRequest,
  InvalidPublicApiTrigger,
  InvalidQueryInputError,
  InvalidFilterError,
  InvalidResetPasswordTokenError,
  InvalidShopifySessionTokenError,
  InvalidStateTransitionError,
  InvalidStoredDataError,
  MisconfiguredActionError,
  MisconfiguredCodeSnippetError,
  MisconfiguredFieldError,
  MisconfiguredValidationError,
  MissingIdentifierInRemoteBlobError,
  MissingRequiredActionError,
  MissingRequiredDataError,
  MissingRequiredFieldError,
  MissingRequiredFieldStorageEpochError,
  MissingRequiredModelError,
  MissingRequiredTriggerError,
  MissingRequiredValidationError,
  NonUniqueConstraintError,
  NoSessionForAuthenticationError,
  NotFoundError,
  NoTransitionError,
  PaymentRequiredError,
  PermissionDeniedError,
  PostgresIndexError,
  PreconditionsFailedForActionError,
  SandboxReloadingError,
  ShopifyAuthenticationError,
  ShopifyForbiddenError,
  ShopifyFrozenError,
  ShopifyInvalidPlanNameError,
  ShopifyRateLimitError,
  ShopifyRequestError,
  ShopifyUnavailableError,
  StillSettingUpError,
  TooManyRequestsError,
  TooManySyncsError,
  UndeployedEnvironmentError,
  UnforkableAppError,
  UniqueValidationNotSupportedError,
  UnknownFieldError,
  UnknownInputTypeError,
  UnknownPlanItemError,
  UnsupportedInSearchError,
  UserInitiatedActionSemanticError,
  UserNotSetOnSessionError,
  WebhookRegistrationError,
] as const;

export type AnyGadgetError = (typeof GadgetErrors)[number] | ErrorGroup;
export type GadgetErrorCode = AnyGadgetError["code"];

// the list of TypeScript diagnostic codes we ignore by default when reporting problems
export const DefaultIgnoredJSDiagnosticCodes = [
  2307, // Cannot find module 'xxx' or its corresponding type declarations
  2589, // type definition is excessively deep and possibly infinite
  80001, // Convert to ES6 module
];

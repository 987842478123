import { StyledSvg } from "baseui/icon";
import { omit } from "lodash";
import React from "react";

export const ChevronIcon = (props: React.ComponentProps<typeof StyledSvg> & { direction: "up" | "down" | "left" | "right" }) => {
  let rotation = 0;
  if (props.direction == "down") {
    rotation = 90;
  } else if (props.direction == "left") {
    rotation = 180;
  } else if (props.direction == "up") {
    rotation = -90;
  }

  return (
    <StyledSvg viewBox="0 0 16 16" {...omit(props, "direction")}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.90139 12.6432L4.90139 12.6432C4.90141 12.7327 4.9263 12.8206 4.97357 12.8958C5.02088 12.971 5.08877 13.0305 5.1694 13.0655C5.25013 13.1006 5.33923 13.1092 5.42496 13.0899C5.51037 13.0707 5.58746 13.0249 5.6469 12.9595L9.97561 8.32129L9.97562 8.32127C10.056 8.23516 10.1 8.11976 10.1 8.00077C10.1 7.88178 10.056 7.76639 9.97562 7.68027L9.97561 7.68026L5.64707 3.04221C5.5873 2.9758 5.50937 2.92928 5.42295 2.90994C5.33609 2.89051 5.24584 2.89974 5.1644 2.93604C5.08307 2.9723 5.01503 3.0335 4.96833 3.11048L5.05382 3.16235L4.96833 3.11048C4.92166 3.18739 4.89812 3.27695 4.90012 3.36749C4.90272 3.48564 4.94868 3.59924 5.02997 3.68325L9.05936 8.00077L5.02978 12.3185C4.98882 12.3613 4.95659 12.412 4.93467 12.4675C4.91266 12.5233 4.9014 12.583 4.90139 12.6432Z"
        fill={props.color ?? "currentcolor"}
        style={{
          transform: `rotate3d(0, 0, 1, ${rotation}deg)`,
          transformOrigin: "center",
          transitionDuration: "0.125s",
          transitionProperty: "transform",
        }}
      />
    </StyledSvg>
  );
};

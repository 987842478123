import { merge } from "lodash";
import { FieldType } from "./type-system/FieldType";

/**
 * The object describing all flag state for any context in the system
 * Flags are powered by LaunchDarkly. The LaunchDarkly flag is named after the path to the flag in this object, like `features.fieldTypes.Computed`.
 */
export type GadgetFlags = {
  features: {
    fieldTypes: {
      [FieldType.Computed]: boolean;
      [FieldType.Color]: boolean;
      [FieldType.Money]: boolean;
    };
    typescript: boolean;
    defaultLanguageSelection: boolean;
    darkMode: boolean;
    ideJSErrorChecking: boolean;
    actionsAsCode: boolean;
    fileBasedActions: boolean;
    computedViews: boolean;
    editorCodeGen: boolean;
    actionsExplainerPopup: boolean;
    shopifyPayloadFilters: boolean;
    stateInActionCode: boolean;
    codeMirrorEditor: boolean;
    shopifyCustomerAuthentication: boolean;
    elasticsearchSearchAPI: boolean;
    customerAuthenticationV2: boolean;
    computedFieldFiltering: boolean;
    bigCommerceConnection: boolean;
    editorAssistant: boolean;
  };
  internal: {
    websocketRateLimitMode: string;
    canarySandbox: boolean;
    stagingLoki: boolean;
    rateLimitMode: "off" | "warn" | "enforce";
    shopifyWebhookLoopDetection: boolean;
    upgradingFission: boolean;
    upgradingMainNodes: boolean;
    dedicatedColumnsStrategy: boolean;
    apiKeysInBrowserClients: boolean;
    throttleExchange: boolean;
    edgeFrameworkVersion: boolean;
    blackholeWebhooks: boolean;
    selectFrameworkVersionOnCreate: boolean;
    gellyVersion: "legacy" | "main";
    useTemporalBlueCluster: boolean;
    skipSearchVectorUpdate: boolean;
    gellyFilterSortCompiler: boolean;
  };
};

/** Default flag state used for tests in state-trees and web */
export const TestFlagDefaultState: GadgetFlags = {
  features: {
    fieldTypes: {
      [FieldType.Computed]: false,
      [FieldType.Color]: false,
      [FieldType.Money]: false,
    },
    typescript: false,
    darkMode: false,
    defaultLanguageSelection: false,
    ideJSErrorChecking: false,
    actionsAsCode: false,
    fileBasedActions: false,
    computedViews: true,
    editorCodeGen: false,
    actionsExplainerPopup: false,
    shopifyPayloadFilters: false,
    stateInActionCode: false,
    codeMirrorEditor: false,
    shopifyCustomerAuthentication: false,
    elasticsearchSearchAPI: false,
    customerAuthenticationV2: false,
    computedFieldFiltering: true,
    bigCommerceConnection: true,
    editorAssistant: true,
  },
  internal: {
    websocketRateLimitMode: "enforce",
    canarySandbox: false,
    stagingLoki: false,
    rateLimitMode: "enforce",
    shopifyWebhookLoopDetection: false,
    upgradingFission: false,
    upgradingMainNodes: false,
    dedicatedColumnsStrategy: false,
    apiKeysInBrowserClients: true,
    throttleExchange: false,
    edgeFrameworkVersion: false,
    blackholeWebhooks: false,
    selectFrameworkVersionOnCreate: false,
    gellyVersion: "main",
    useTemporalBlueCluster: false,
    skipSearchVectorUpdate: false,
    gellyFilterSortCompiler: true,
  },
};

export const ClientSideDefaultFlags: GadgetFlags = merge({}, TestFlagDefaultState, {
  features: {
    editorCodeGen: false,
  },
});
